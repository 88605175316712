import React from "react";
import { graphql } from "gatsby";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Block from "../components/_global/editorial/Block";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Spacer from "../components/_global/layout/Spacer";

const LegalNotice = ({ data, ...props }) => {
	const legalData = data.sanityLegalNotice;
	return (
		<Layout title={legalData.common.title}>
			<PageHeader title={legalData.common.title} />

			<Section m="large">
				<Block data={legalData.text} />
			</Section>

			<Spacer s="large" />
		</Layout>
	);
};

export default localize(LegalNotice);

export const query = graphql`
    {
        sanityLegalNotice {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            text {
                ...RegularBlockFragment
            }
        }
    }
`;